import { useState } from 'react';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import styles from './styles.module.css';
import PopUpLayout from '../components/Layout/PopUpLayout';
import TextInput from '../components/TextInput';
import ImageUpload from '../components/ImageUpload';
import CategoryService from '../services/CategoryService';
import Loader from '../components/Loader';

export default function CategoryForm({ category, closeHandler, onSuccess }) {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(category || {});
  const [errors, setErrors] = useState({});

  const resetForm = () => {
    setData({});
  };

  const closeDialog = () => {
    resetForm();
    closeHandler();
  };

  async function submitHandler() {
    return category.id ? updateHandler() : createHandler();
  }

  async function createHandler() {
    setIsLoading(true);
    try {
      const category = await CategoryService.createCategory(data);
      if (data.image?.file) {
        await CategoryService.addCategoryImage(category.id, data.image.file);
      }
      onSuccess();
      resetForm();
      closeDialog();
    } catch (err) {
      console.error(err);
      setErrors({ description: err.message });
    }
    setIsLoading(false);
  }

  async function updateHandler() {
    setIsLoading(true);
    try {
      await CategoryService.updateCategory(category.id, data);
      if (data.image && data.image.file) {
        await CategoryService.addCategoryImage(category.id, data.image.file);
      }
      onSuccess();
      resetForm();
      closeDialog();
    } catch (err) {
      console.error(err);
      setErrors({ description: err.message });
    }
    setIsLoading(false);
  }

  return (
    <PopUpLayout>
      <div className={styles.title}>
        {category.id ? 'Edit Category' : 'Add Category'}
      </div>
      <ImageUpload
        preSelectedImage={data.image}
        onImageSelected={(image) => {
          setData({ ...data, image });
        }}
        shouldClearOnSubmit={false}
        autoSubmit={true}
      />
      <TextInput
        name="name"
        label="Category Name"
        placeholder="Enter name"
        value={data.name}
        error={errors.name}
        onChange={(name) => {
          setData({ ...data, name });
        }}
      />
      <TextInput
        name="description"
        label="Description"
        placeholder="Enter description"
        value={data.description}
        error={errors.description}
        onChange={(description) => {
          setData({ ...data, description });
        }}
      />
      <TextInput
        name="order"
        label="Order"
        placeholder="Enter order"
        value={data.order || ''}
        error={errors.order}
        onChange={(order) => {
          setData({ ...data, order: Number(order) });
        }}
      />
      <Grid
        container
        justifyContent="flex-end"
        spacing={2}
        className={styles.btnWrapper}
      >
        <Grid item>
          <Button
            type="reset"
            variant="contained"
            className={styles.closeBtn}
            onClick={closeDialog}
          >
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            disabled={!data.name || !data.description}
            style={{ position: 'relative' }}
            type="button"
            className={styles.submitBtn}
            onClick={submitHandler}
          >
            <Loader isLoading={isLoading} size={16} />
            Submit
          </Button>
        </Grid>
      </Grid>
    </PopUpLayout>
  );
}
