import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

export default function PageNotFound() {
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <h1 style={{ margin: 20 }}>Page not found</h1>
        <Button
          variant="contained"
          onClick={() => {
            navigate("/");
          }}
        >
          GO TO HOME
        </Button>
      </div>
    </div>
  );
}
