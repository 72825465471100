import React, { useState } from "react";
import TextInput from "../components/TextInput";
import PopUpLayout from "../components/Layout/PopUpLayout";
import Loader from "../components/Loader";
import styles from "./styles.module.css";

function AddCustomerDialog({ closeDialog }) {
  // const [isLoading, setIsLoading] = useState(false)
  const [isLoading] = useState(false);

  return (
    <PopUpLayout className={styles.size}>
      <div className={styles.title}>Customer Details</div>
      <TextInput name="Name" label="Name" placeholder="" />
      <TextInput name="Amount" label="Amount" placeholder="" />
      <TextInput name="percentage" label="Percentage" placeholder="" />
      <TextInput
        name="Over Previous Charge"
        label="Over Previous Charge"
        placeholder=""
      />
      <TextInput name="Minimum Value" label="Minimum Value" placeholder="" />
      <TextInput name="Maximum Value" label="Maximum Value" placeholder="" />
      <div className={styles.btnWrapper}>
        <button type="reset" className={styles.closeBtn} onClick={closeDialog}>
          Cancel
        </button>
        <button
          style={{ position: "relative" }}
          type="button"
          className={styles.submitBtn}
        >
          <Loader isLoading={isLoading} size={16} />
          Submit
        </button>
      </div>
    </PopUpLayout>
  );
}

export default AddCustomerDialog;
