import OrganizationGuard from "../../components/OrganizationGuard";
import ConsoleLayout from "../../components/Layout/ConsoleLayout";
import styles from "./CustomerPage.module.css";
import AddCustomerDialog from "../../popups/AddCustomerDialog";
import { useState } from "react";
export default function Customers() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <OrganizationGuard>
      <ConsoleLayout>
        <div className={styles.titleBarWrapper}>
          <div className={styles.titleWrapper}>
            <span className={styles.title}>Customer Page</span>
          </div>
          <span className={styles.coloredBtn} onClick={handleClickOpen}>
            Add Customer
          </span>
        </div>
        {open && <AddCustomerDialog closeDialog={handleClickClose} />}
      </ConsoleLayout>
    </OrganizationGuard>
  );
}
