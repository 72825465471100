import OrganizationGuard from "../../components/OrganizationGuard";
import ConsoleLayout from "../../components/Layout/ConsoleLayout";
import styles from './charges.module.css'
import AddChargeGroupDialog from '../../popups/AddChargeGroupDialog'
import {useState} from 'react'

export default function ChargeConfiguration() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
  };

  return (
    <OrganizationGuard>
      <ConsoleLayout>
        <div className={styles.titleBarWrapper}>
          <div className={styles.titleWrapper}>
            <span className={styles.title}>Charge Groups Page</span>
          </div>
          <span className={styles.coloredBtn} onClick={handleClickOpen}>
            Add ChargeGroup
          </span>
        </div>
        {open && <AddChargeGroupDialog closeDialog={handleClickClose} />}
      </ConsoleLayout>
    </OrganizationGuard>
  );
}
