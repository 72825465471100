import { useContext } from "react";
import { Navigate } from "react-router-dom";
import UserContext from "../contexts/UserContext";
import OrganizationHelper from "../helpers/OrganizationHelper";
import { ROUTES } from "../constants";
import OrganizationGuard from "./OrganizationGuard";

export default function ConsoleFirstPageRouter() {
  return (
    <OrganizationGuard>
      <ConsoleFirstPageRouterLogic />
    </OrganizationGuard>
  );
}

function ConsoleFirstPageRouterLogic() {
  const { organization } = useContext(UserContext);
  const firstPageConfig = OrganizationHelper.getFirstPageConfig(organization);
  return <Navigate to={firstPageConfig?.path || ROUTES.CONSOLE_ORDERS} />;
}
