import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import TextInput from "../components/TextInput";
import withFormPageLayout from "../components/Layout/UserLayout";
import { ROUTES } from "../constants";
import styles from "./LoginPage.module.css";
import Loader from "../components/Loader";
import UserContext from "../contexts/UserContext";

function LoginForm() {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ username: '', password: "" })
  const [error, setError] = useState({})
  const { initiateLogin } = useContext(UserContext);
  const navigate = useNavigate();

  async function submitHandler() {

    const _error = {}
    if (!data.username) {
      _error.username = "Username is mandatory"
    }

    if (!data.password) {
      _error.password = "Password is mandatory"
    }

    setError(_error);
    if (Object.keys(_error).length > 0) {
      return;
    }

    setIsLoading(true);
    try {
      await initiateLogin(data);
      navigate(ROUTES.CONSOLE);
    } catch (err) {
      console.error(err.message);
      setError({ ...error, password: "Username or password is incorrect" })
    }
    setIsLoading(false);
  }
  return (
    <>
      <h2 className={styles.title}>Login</h2>
      <TextInput
        label="Username"
        name="username"
        placeholder="Username"
        value={data.username}
        error={error.username}
        onChange={(value) => setData({ ...data, username: value })}
        size="small"
      />
      <TextInput
        label="Password"
        type="password"
        name="password"
        placeholder="Password"
        value={data.password}
        error={error.password}
        onChange={(value) => setData({ ...data, password: value })}
        size="small"
      />
      <Link to={ROUTES.FORGOT_PASSWORD} className={styles.link}>
        Forgot Password?
      </Link>
      <button
        style={{ position: "relative" }}
        className={styles.btn}
        onClick={submitHandler}
        type="submit"
        disabled={isLoading}
      >
        <Loader isLoading={isLoading} />
        Login
      </button>
    </>
  );
}
export default withFormPageLayout(LoginForm);
