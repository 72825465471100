import styles from './styles.module.css';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useState } from 'react';
import { nanoid } from 'nanoid';

export default function ImageUpload({
  preSelectedImage,
  onImageSelected,
  shouldClearOnSubmit = true,
  autoSubmit = false,
}) {
  const [selectedImage, setSelectedImage] = useState(
    preSelectedImage ? { url: preSelectedImage, id: nanoid() } : null
  );
  const onImageSelectedHandler = (event) => {
    const image = { file: event.currentTarget.files[0], id: nanoid() };
    setSelectedImage(image);
    if (autoSubmit) {
      onImageSubmit(image);
    }
  };

  const onImageClearHandler = () => {
    setSelectedImage(null);
    if (autoSubmit) {
      onImageSubmit(null);
    }
  };

  const onImageSubmit = (image) => {
    onImageSelected(image);
    if (shouldClearOnSubmit) {
      setSelectedImage(null);
    }
  };

  return (
    <div className={styles.uploadWrapper}>
      {selectedImage ? (
        <>
          <div className={styles.uploadSection}>
            <img
              src={
                selectedImage.file
                  ? URL.createObjectURL(selectedImage.file)
                  : selectedImage.url
              }
              className={styles.productImage}
              alt=""
            />
          </div>

          <div>
            {!autoSubmit && (
              <label
                className={`${styles.uploadBtn} ${styles.spacer}`}
                onClick={() => onImageSubmit(selectedImage)}
              >
                Add Image
              </label>
            )}
            <label
              className={`${styles.uploadBtn} ${styles.spacer}`}
              onClick={onImageClearHandler}
            >
              Clear Image
            </label>
          </div>
        </>
      ) : (
        <>
          <div className={styles.uploadSection}>
            <CloudUploadOutlinedIcon className={styles.uploadIcon} />
            <span className={styles.message}>Upload the image...</span>
          </div>
          <input
            id="upload-image"
            type="file"
            hidden
            onChange={onImageSelectedHandler}
          />

          <label htmlFor="upload-image" className={styles.uploadBtn}>
            Upload Image
          </label>
        </>
      )}
    </div>
  );
}
