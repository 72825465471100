import { BASE_API_URL } from "../constants";
import ApiHelper from "../helpers/ApiHelper";
import CacheHelper from "../helpers/CacheHelper";

const addItem = async (data) => {
  const url = `${BASE_API_URL}/products`;
  const resp = await ApiHelper.request({
    url,
    method: "POST",
    data,
    requireAuth: true,
    requireOrg: true,
  });
  _clearCache();
  return resp;
};

const getItemsByCategory = async (categoryId) => {
  const cacheKey = `items-category-${categoryId}`;
  const cacheValue = CacheHelper.get(cacheKey);
  if (cacheValue) {
    return cacheValue;
  }

  const url = `${BASE_API_URL}/products`;
  const items = await ApiHelper.request({
    url,
    method: "GET",
    params: { categoryId },
    requireAuth: true,
    requireOrg: true,
  });

  CacheHelper.set(cacheKey, items);
  items.forEach((item) => {
    const itemCacheKey = `item-${item.id}`;
    CacheHelper.set(itemCacheKey, item);
  });
  return items;
};

const getItem = async (itemId) => {
  const cacheKey = `item-${itemId}`;
  const cacheValue = CacheHelper.get(cacheKey);
  if (cacheValue) {
    return cacheValue;
  }

  const url = `${BASE_API_URL}/products/${itemId}`;
  const item = await ApiHelper.request({
    url,
    method: "GET",
    requireAuth: true,
    requireOrg: true,
  });

  CacheHelper.set(cacheKey, item);
  return item;
};

const deleteItem = async (itemId) => {
  const url = `${BASE_API_URL}/products/${itemId}`;
  const resp = await ApiHelper.request({
    url,
    method: "DELETE",
    requireAuth: true,
    requireOrg: true,
  });
  _clearCache();
  return resp;
};

const updateItem = async (itemId, data) => {
  const url = `${BASE_API_URL}/products/${itemId}`;
  const resp = await ApiHelper.request({
    url,
    data,
    method: "PATCH",
    requireAuth: true,
    requireOrg: true,
  });
  _clearCache();
  return resp;
};

const addItemImage = async (itemId, file) => {
  const formData = new FormData();
  formData.append("file", file);
  const url = `${BASE_API_URL}/products/${itemId}/image`;
  const resp = await ApiHelper.request({
    url,
    data: formData,
    method: "POST",
    requireAuth: true,
    requireOrg: true,
  });
  _clearCache();
  return resp;
};

const _clearCache = () => {
  CacheHelper.deleteByPrefix("item");
};

const ItemService = {
  addItem,
  getItemsByCategory,
  getItem,
  deleteItem,
  updateItem,
  addItemImage,
};

export default ItemService;
