import { useState, useEffect, useContext, useCallback } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import EastSharpIcon from "@mui/icons-material/EastSharp";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./CategoryListPage.module.css";
import { Breadcrumbs } from "@mui/material";
import ItemCard from "../components/ItemCard";
import ItemService from "../services/ItemService";
import ConfirmPopup from "../components/Alert/ConfirmPopup";
import OrganizationGuard from "../components/OrganizationGuard";
import ConsoleLayout from "../components/Layout/ConsoleLayout";
import { ROUTES } from "../constants";
import CategoryService from "../services/CategoryService";
import AlertMessageContext from "../contexts/AlertMessageContext";
import Info from "../components/Alert/Info";
import Loader from "../components/Loader";

export default function CategoryDetailsPage() {
  return (
    <OrganizationGuard>
      <ConsoleLayout>
        <CategoryDetails />
      </ConsoleLayout>
    </OrganizationGuard>
  );
}

function CategoryDetails() {
  const { categoryId } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [category, setCategory] = useState(null);
  const [items, setItems] = useState(null);
  const [selectedItemForDelete, setSelectedItemForDelete] = useState(null);
  const { postErrorAlert } = useContext(AlertMessageContext);
  const navigate = useNavigate();

  const openAddItem = () => {
    navigate(ROUTES.CONSOLE_ITEM_CREATE.replace(":categoryId", categoryId));
  };
  const editHandler = (item) => {
    navigate(
      ROUTES.CONSOLE_ITEM_EDIT.replace(":categoryId", categoryId).replace(
        ":itemId",
        item.id
      )
    );
  };
  const openDeleteItemConfirmDialog = (item) => setSelectedItemForDelete(item);
  const closeDeleteItemConfirmDialog = () => setSelectedItemForDelete(null);
  const deleteHandler = async (item) => {
    setIsLoading(true);
    try {
      await ItemService.deleteItem(item.id);
      await getItems().then();
    } catch (error) {
      postErrorAlert(error.message);
    }
    setIsLoading(false);
  };

  const getItems = useCallback(async () => {
    try {
      const items = await ItemService.getItemsByCategory(categoryId);
      setItems(items);
    } catch (error) {
      postErrorAlert(error.message);
    }
  }, [categoryId, postErrorAlert]);

  const getCategoryDetails = useCallback(async () => {
    try {
      const category = await CategoryService.getCategory(categoryId);
      setCategory(category);
    } catch (error) {
      postErrorAlert(error.message);
    }
  }, [categoryId, postErrorAlert]);

  useEffect(() => {
    const loadDetails = async () => {
      setIsLoading(true);
      await Promise.all([getCategoryDetails(), getItems()]);
      setIsLoading(false);
    };
    loadDetails().then();
  }, [categoryId, setIsLoading, postErrorAlert, getItems, getCategoryDetails]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className={styles.titleBarWrapper}>
        <div className={styles.titleWrapper}>
          {category && (
            <>
              <span className={styles.title}>{category?.name}</span>
              <Breadcrumbs
                separator={<EastSharpIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Link
                  to={ROUTES.CONSOLE_PRODUCTS}
                  underline="hover"
                  key="1"
                  className={styles.link}
                >
                  Categories
                </Link>
                <span key="2" className={styles.activeLink}>
                  {category?.name}
                </span>
              </Breadcrumbs>
            </>
          )}
        </div>
        <div className={styles.filterWrapper}>
          <div className={styles.searchBarWrapper}>
            <SearchIcon className={styles.searchIcon} />
            <input
              type="text"
              className={styles.searchBar}
              placeholder="Search"
            />
          </div>
          <span className={styles.coloredBtn} onClick={openAddItem}>
            Add New Item
          </span>
          {selectedItemForDelete && (
            <ConfirmPopup
              data={selectedItemForDelete}
              alertTitle="Do you want to delete item?"
              alertContent={`You are going to delete '${selectedItemForDelete.name}' item.
                                This action is permanent and can't retrieve the details once deleted.`}
              successBtnName="Delete"
              handleClose={closeDeleteItemConfirmDialog}
              handleSuccess={deleteHandler}
            />
          )}
        </div>
      </div>
      <div className={styles.cardsWrapper}>
        {items && !items.length ? (
          <Info
            title="No Items Found"
            content="This category does not have an item to list"
          />
        ) : (
          items?.map((item) => (
            <ItemCard
              key={item.id}
              item={item}
              editHandler={editHandler}
              deleteHandler={openDeleteItemConfirmDialog}
            />
          ))
        )}
      </div>
    </>
  );
}
