import OrganizationGuard from "../../components/OrganizationGuard";
import ConsoleLayout from "../../components/Layout/ConsoleLayout";
export default function UserPermissions() {
  return (
    <OrganizationGuard>
      <ConsoleLayout>
        <h2>User Permissions</h2>
      </ConsoleLayout>
    </OrganizationGuard>
  );
}
