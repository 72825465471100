import { TextField, MenuItem } from "@mui/material";
export default function SelectBox({ formikObj, ...props }) {
  return (
    <TextField
      select
      id="standard-basic"
      label={props.label ? props.label : props.name}
      variant="standard"
      type="text"
      fullWidth
      sx={{ mb: 3 }}
      onChange={formikObj.handleChange}
      value={formikObj.values[props.name]}
      error={formikObj.errors[props.name] ? true : false}
      helperText={
        formikObj.errors[props.name] ? formikObj.errors[props.name] : null
      }
      {...props}
    >
      {props.options.map((option) => (
        <MenuItem value={option}>{option}</MenuItem>
      ))}
    </TextField>
  );
}
