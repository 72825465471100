import styles from "./styles.module.css";
import { useNavigate } from "react-router-dom";
import ItemPlaceHolderImage from "../PlaceHolderImage";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useContext } from "react";
import UserContext from "../../contexts/UserContext";

export default function ItemCard({ item, link, editHandler, deleteHandler }) {
  const navigate = useNavigate();
  const { name, description, price, images } = item;
  const image = images ? images[0] : null;
  const onEditButtonClicked = () => editHandler(item);
  const onDeleteButtonClicked = () => deleteHandler(item);
  const navigateToNextPage = () => navigate(link);
  const { organization } = useContext(UserContext);
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.actionWrapper}>
        <EditIcon
          fontSize="small"
          color="disabled"
          onClick={onEditButtonClicked}
          className={styles.pointer}
        />
        <DeleteIcon
          fontSize="small"
          color="disabled"
          onClick={onDeleteButtonClicked}
          className={styles.pointer}
        />
      </div>
      <img
        src={image?.url || ItemPlaceHolderImage}
        alt={name}
        className={styles.image}
        onClick={navigateToNextPage}
      />
      {name && (
        <span onClick={navigateToNextPage} className={styles.title}>
          {name}
        </span>
      )}
      {description && (
        <span className={styles.itemDescription}>{description}</span>
      )}
      {price && (
        <span className={styles.price}>
          {organization?.config?.currency || "Rs"}
          {price}
        </span>
      )}
    </div>
  );
}
