import { BrowserRouter, Routes, Route } from "react-router-dom";
import CategoryDetailsPage from "./pages/CategoryDetailsPage";
import CategoryListPage from "./pages/CategoryListPage";
import Settings from "./pages/settings";
import LoginPage from "./pages/LoginPage";
import SalesManagementPage from "./pages/SalesManagement";
import CustomerPage from "./pages/Customer/CustomersPage";
import BillSummaryReportPage from "./pages/Reports/BillSummaryReport";
import ChargeGroupPage from "./pages/ChargeConfiguration/ChargeGroupPage";
import UserPermissions from "./pages/UserPermissions/UserPermission";
import PriceCategory from "./pages/PriceCategory/PriceCategory";
import { LoadingProvider } from "./contexts/loadingContext";
import { AlertMessageProvider } from "./contexts/AlertMessageContext";
import { UserProvider } from "./contexts/UserContext";
import SplashPage from "./pages/SplashPage";
import { ROUTES } from "./constants";
import PageNotFound from "./pages/PageNotFound";
import ConsoleFirstPageRouter from "./components/ConsoleFirstPageRouter";
import ItemFormPage from "./pages/ItemFormPage";
import ChargesPage from "./pages/ChargeConfiguration/ChargesPage";

export default function App() {
  return (
    <AlertMessageProvider>
      <UserProvider>
        <LoadingProvider>
          <BrowserRouter>
            <Routes>
              <Route path={ROUTES.LOGIN} element={<LoginPage />} />
              <Route
                path={ROUTES.CONSOLE_SALES}
                element={<SalesManagementPage />}
              />
              <Route
                path={ROUTES.CONSOLE_PRODUCTS}
                element={<CategoryListPage />}
              />
              <Route
                path={ROUTES.CONSOLE_CUSTOMER}
                element={<CustomerPage />}
              />
              <Route
                path={ROUTES.CONSOLE_REPORT}
                element={<BillSummaryReportPage />}
              />
              <Route
                path={ROUTES.CONSOLE_CHARGE_CONFIG}
                element={<ChargeGroupPage />}
              />
              <Route path={ROUTES.CONSOLE_CHARGES} element={<ChargesPage />} />
              <Route
                path={ROUTES.CONSOLE_USER_PERMISSIONS}
                element={<UserPermissions />}
              />
              <Route
                path={ROUTES.CONSOLE_PRICE_CATEGORY}
                element={<PriceCategory />}
              />
              <Route
                path={ROUTES.CONSOLE_CATEGORY_DETAILS}
                element={<CategoryDetailsPage />}
              />
              <Route
                path={ROUTES.CONSOLE_ITEM_CREATE}
                element={<ItemFormPage />}
              />
              <Route
                path={ROUTES.CONSOLE_ITEM_EDIT}
                element={<ItemFormPage />}
              />
              <Route path={ROUTES.CONSOLE_SETTINGS} element={<Settings />} />
              <Route
                path={ROUTES.CONSOLE}
                element={<ConsoleFirstPageRouter />}
              />
              <Route path="/" element={<SplashPage />} />
              <Route path="*" element={<PageNotFound />} />
            </Routes>
          </BrowserRouter>
        </LoadingProvider>
      </UserProvider>
    </AlertMessageProvider>
  );
}
