export const BASE_API_URL =
  process.env.REACT_APP_BASE_API_URL ||
  "https://sanoft-mini-erp.uc.r.appspot.com/api";

export const DEFAULT_ERROR_MESSAGE =
  "Oops, Something went wrong. If this persists, please contact us.";

export const SUPPORTED_FORMATS = [
  "image/jpg",
  "image/jpeg",
  "image/gif",
  "image/png",
];

export const FILE_SIZE = 5000000;

export const HEADERS = {
  AUTH: "Authorization",
};

export const STORAGE_KEYS = {
  REFRESH_TOKEN: "refresh-token",
  ACCESS_TOKEN: "access-token",
  SELECTED_ORGANIZATION_ID: "selected-organization",

  // Deprecated
  SHOP_ID: "x-shop-id",
};

export const ROUTES = {
  CONSOLE_SALES: "/console/sales",
  CONSOLE_PRODUCTS: "/console/products",
  CONSOLE_CUSTOMER: "/console/customers",
  CONSOLE_REPORT: "/console/report",
  CONSOLE_CHARGE_CONFIG: "/console/charge-config",
  CONSOLE_CHARGES: "/console/charges",
  CONSOLE_USER_PERMISSIONS: "/console/permissions",
  CONSOLE_PRICE_CATEGORY: "/console/prices",
  CONSOLE_CATEGORY_DETAILS: "/console/products/:categoryId",
  CONSOLE_ITEM_CREATE: "/console/products/:categoryId/items",
  CONSOLE_ITEM_EDIT: "/console/products/:categoryId/items/:itemId",
  CONSOLE_SETTINGS: "/console/settings",
  CONSOLE: "/console",
  LOGIN: "/login",
  FORGOT_PASSWORD: "/forgot-password",
  CREATE_ORGANIZATION: "/create-organization",
};

// Read more details here
// https://sanoft.atlassian.net/wiki/spaces/IDEAS/pages/21823489/Sanoft+POS+-+Sale+details
export const SALE_STATUS = {
  DRAFT: "draft",
  CONFIRMED: "confirmed",
  PAID: "paid",
  PACKING: "packing",
  DELIVERED: "delivered",
  CANCELLED: "cancelled",
};
