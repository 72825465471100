import lodash from "lodash";
import styles from "./styles.module.css";
import PopUpLayout from "../components/Layout/PopUpLayout";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import ItemService from "../services/ItemService";

export default function OrderDetailsPopup({ sale, handleClose }) {
  const [saleItems, setSaleItems] = useState(null);

  useEffect(() => {
    if (!sale || !sale.items) {
      return;
    }
    const _convertSales = async () => {
      const _saleItems = await Promise.all(
        Object.keys(sale.items).map(async (saleItemId) => {
          const saleItem = sale.items[saleItemId];
          let product = {};
          try {
            product = await ItemService.getItem(saleItem.itemId);
          } catch (error) {
            console.error(error);
          }
          return { ...saleItem, product };
        })
      );
      setSaleItems(_saleItems);
    };
    _convertSales().then();
  }, [sale]);

  return (
    <>
      <div className="no-print">
        <PopUpLayout>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <h3 className={styles.title}>Order Details</h3>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
          </div>
          <SaleDetails sale={sale} saleItems={saleItems} />
        </PopUpLayout>
      </div>
      <div className="print print-layout">
        <SaleDetailsPrint sale={sale} saleItems={saleItems} />
      </div>
    </>
  );
}

function SaleDetails({ sale, saleItems }) {
  return (
    <div>
      <Grid container style={{ marginTop: 16 }}>
        {sale.customer &&
          Object.keys(sale.customer).map((key) => {
            return (
              <Grid item xs={6} key={key}>
                <span>{lodash.startCase(key)}</span>:{" "}
                <span className={styles.orderdetail}>{sale.customer[key]}</span>
              </Grid>
            );
          })}
      </Grid>

      {saleItems && (
        <div style={{ marginTop: 10 }}>
          <div className={styles.productsTitle}>Product Details</div>
          <Table style={{ marginTop: 8 }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ border: "1px solid #ccc" }}>
                  Product Name
                </TableCell>
                <TableCell style={{ border: "1px solid #ccc" }} align="right">
                  Quantity
                </TableCell>
                <TableCell style={{ border: "1px solid #ccc" }} align="right">
                  Price
                </TableCell>
                <TableCell style={{ border: "1px solid #ccc" }} align="right">
                  Payable
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {saleItems.map((saleItem) => (
                <TableRow
                  key={saleItem.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={{ border: "1px solid #ccc" }} scope="row">
                    {saleItem.product?.name}
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ccc" }} align="right">
                    {saleItem.quantity}
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ccc" }} align="right">
                    {saleItem.price}
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ccc" }} align="right">
                    {saleItem.payable}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}

      <Grid
        container
        justifyContent="flex-end"
        spacing={2}
        style={{ marginTop: 16 }}
      >
        {sale.customer?.mobile && (
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                window.open(
                  `whatsapp://send?text=Hi, Your order '${sale.id}' update&phone=${sale.customer.mobile}`
                );
              }}
            >
              Whatsapp
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button variant="contained" onClick={window.print}>
            Print
          </Button>
        </Grid>
      </Grid>
    </div>
  );
}

function SaleDetailsPrint({ sale, saleItems }) {
  return (
    <div>
      <Grid container style={{ marginTop: 16 }}>
        <Grid item xs={12}>
          <span>Order ID</span>:{" "}
          <span>
            <b>{sale.id}</b>
          </span>
        </Grid>
        {sale.customer &&
          Object.keys(sale.customer).map((key) => {
            return (
              <Grid item xs={12} key={key}>
                <span>{lodash.startCase(key)}</span>:{" "}
                <span>
                  <b>{sale.customer[key]}</b>
                </span>
              </Grid>
            );
          })}
      </Grid>

      {saleItems && (
        <div style={{ marginTop: 10 }}>
          <div className={styles.productsTitle}>Product Details</div>
          <Table style={{ marginTop: 8 }}>
            <TableHead>
              <TableRow>
                <TableCell style={{ border: "1px solid #ccc" }}>
                  Product Name
                </TableCell>
                <TableCell style={{ border: "1px solid #ccc" }} align="right">
                  Quantity
                </TableCell>
                <TableCell style={{ border: "1px solid #ccc" }} align="right">
                  Price
                </TableCell>
                <TableCell style={{ border: "1px solid #ccc" }} align="right">
                  Payable
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {saleItems.map((saleItem) => (
                <TableRow
                  key={saleItem.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell style={{ border: "1px solid #ccc" }} scope="row">
                    {saleItem.product?.name}
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ccc" }} align="right">
                    {saleItem.quantity}
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ccc" }} align="right">
                    {saleItem.price}
                  </TableCell>
                  <TableCell style={{ border: "1px solid #ccc" }} align="right">
                    {saleItem.payable}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
}
