import React from "react";
import lodash from "lodash";
import { Button, Card, Grid } from "@mui/material";
import { SALE_STATUS } from "../../constants";
// import styles from "./styles.module.css";

export default function OrderCard({ sale, setSelectedSale, updateSaleStatus }) {
  const _handleOnSaleClick = () => {
    setSelectedSale(sale);
  };
  return (
    <Grid item xs={3}>
      <Card style={{ padding: 10, height: "100%" }}>
        <Grid
          container
          direction="column"
          justifyContent="space-between"
          style={{ height: "100%" }}
        >
          <Grid
            item
            style={{ width: "100%", cursor: "pointer" }}
            onClick={_handleOnSaleClick}
          >
            <h4>Sale</h4>
            <div style={{ wordWrap: "break-word" }}>
              <b>ID:</b> {sale.id}
            </div>
            <div>
              <b>Status:</b> {sale.status}
            </div>
            {!!sale.customer &&
              Object.keys(sale.customer).map((customerDetailKey) => (
                <div key={customerDetailKey}>
                  <b>{lodash.startCase(customerDetailKey)}:</b>{" "}
                  {sale.customer[customerDetailKey]}
                </div>
              ))}
          </Grid>

          <Grid
            item
            container
            spacing={1}
            justifyContent="space-between"
            style={{ marginTop: 5 }}
          >
            <Grid item>
              <Button
                variant="contained"
                size="small"
                color="error"
                onClick={() => {
                  updateSaleStatus(sale.id, SALE_STATUS.CANCELLED);
                }}
              >
                Cancel
              </Button>
            </Grid>
            {sale.status === SALE_STATUS.DRAFT && (
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => {
                    updateSaleStatus(sale.id, SALE_STATUS.CONFIRMED);
                  }}
                >
                  Accept
                </Button>
              </Grid>
            )}
            {sale.status === SALE_STATUS.CONFIRMED && (
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  color="secondary"
                  onClick={() => {
                    updateSaleStatus(sale.id, SALE_STATUS.PACKING);
                  }}
                >
                  Prepare
                </Button>
              </Grid>
            )}
            {sale.status === SALE_STATUS.PACKING && (
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  color="success"
                  onClick={() => {
                    updateSaleStatus(sale.id, SALE_STATUS.DELIVERED);
                  }}
                >
                  Deliver
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
  // return (
  //   <div
  //     className={styles.orderCardWrapper}
  //     onClick={() => {
  //       setSelectedOrder(sale);
  //     }}
  //   >
  //     <div className={styles.cardTitle}>Order {sale.orderNo}</div>
  //     <div className={styles.cardContent}>
  //       <div className={styles.PersonalDetailsWrapper}>
  //         <div className={styles.personalDetails}>
  //           <span className={styles.name}>Mr/Mrs : {sale.customer.name}</span>
  //           <span className={styles.name}>Order Id : {sale.id}</span>
  //           <span className={styles.address}>{sale.address}</span>
  //           <span className={styles.phone}>{sale.mobile}</span>
  //         </div>
  //         {/* <img className={styles.profileImg} src={profile} alt="" /> */}
  //       </div>
  //       <div className={styles.descWrapper}>
  //         <span className={styles.descTitle}>Description</span>
  //         <span className={styles.desc}>{sale.description}</span>
  //       </div>
  //     </div>
  //   </div>
  // );
}
