import { useState, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import ApiHelper from "../helpers/ApiHelper";
import AlertMessageContext from "../contexts/AlertMessageContext";
import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import styles from "./LoginDialog.module.css";

export default function CreateOrganizationDialog({
  show,
  handleClose,
  onSuccess,
}) {
  const [formData, setFormData] = useState({});
  const [formErrors, setFormErrors] = useState({});
  const [createInProgress, setCreateInProgress] = useState(false);
  const { postErrorAlert } = useContext(AlertMessageContext);

  const handleCreateOrganization = async () => {
    const success = validateInput();
    if (!success) {
      return;
    }

    setCreateInProgress(true);
    try {
      const organization = await ApiHelper.createOrganization(formData);
      onSuccess(organization);
    } catch (error) {
      postErrorAlert(error.message);
    }
    setCreateInProgress(false);
  };

  const validateInput = () => {
    const errors = {};
    if (!formData.name) {
      errors.name = { message: "Shop name is mandatory" };
    }
    if (!formData.billingAddress) {
      errors.billingAddress = { message: "Address is mandatory" };
    }
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  return (
    <Dialog open={show} onClose={handleClose} maxWidth="sm" fullWidth={true}>
      <DialogTitle>
        Create Shop
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <TextField
          margin="normal"
          error={Boolean(formErrors.name)}
          label="Shop name"
          helperText={formErrors.name?.message}
          fullWidth
          onChange={(event) => {
            setFormData({
              ...formData,
              name: event.target.value,
            });
          }}
        />
        <TextField
          margin="normal"
          error={Boolean(formErrors.billingAddress)}
          label="Address"
          type="text"
          helperText={formErrors.billingAddress?.message}
          fullWidth
          multiline
          rows={4}
          onChange={(event) => {
            setFormData({
              ...formData,
              billingAddress: event.target.value
                .split("\n")
                .map((i) => i.trim()),
            });
          }}
        />
        <TextField
          margin="normal"
          error={Boolean(formErrors.taxId)}
          label="GST Number (Optional)"
          helperText={formErrors.taxId?.message}
          fullWidth
          onChange={(event) => {
            setFormData({
              ...formData,
              taxId: event.target.value,
            });
          }}
        />

        <Grid container justifyContent="center" style={{ marginTop: 20 }}>
          <LoadingButton
            className={styles.loginButton}
            loading={createInProgress}
            onClick={handleCreateOrganization}
            variant="contained"
          >
            CREATE SHOP
          </LoadingButton>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
