import React, { useState } from "react";
import TextInput from "../components/TextInput";
import PopUpLayout from "../components/Layout/PopUpLayout";
import Loader from "../components/Loader";
import styles from "./styles.module.css";

function AddChargeGroupDialog({ closeDialog }) {
  // const [isLoading, setIsLoading] = useState(false);
  const [isLoading] = useState(false);

  return (
    <PopUpLayout className={styles.size}>
      <div className={styles.title} style={{ paddingBottom: 5 }}>
        Charge Groups
      </div>
      <TextInput name="name" label="Name" placeholder="" />
      <div className={styles.btnWrapper}>
        <button type="reset" className={styles.closeBtn} onClick={closeDialog}>
          Cancel
        </button>
        <button
          style={{ position: "relative" }}
          type="button"
          className={styles.submitBtn}
        >
          <Loader isLoading={isLoading} size={16} />
          Submit
        </button>
      </div>
    </PopUpLayout>
  );
}

export default AddChargeGroupDialog;
