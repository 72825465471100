const BackendService = {
  post: () => {},
  get: () => {},
  patch: () => {},
  destroy: () => {},
};

const getSettings = () => BackendService.get("/settings");
const addSettings = (data) => BackendService.put("/settings", data);

const settingService = {
  getSettings,
  addSettings,
};

export default settingService;
