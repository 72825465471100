const CACHE_TTL = 60000 * 5; // 5 minutes

const cache = {};

const set = (key, value, options = {}) => {
  const _cache = options.cache || cache;
  _cache[key] = {
    expiry: Date.now() + (options.ttl || CACHE_TTL),
    value,
  };
};

const del = (key, options = {}) => {
  const _cache = options.cache || cache;
  delete _cache[key];
};

const deleteByPrefix = (prefix, options = {}) => {
  const _cache = options.cache || cache;
  const keys = Object.keys(_cache).filter((key) => key.startsWith(prefix));
  keys.forEach((key) => delete _cache[key]);
};

const get = (key, options = {}) => {
  const _cache = options.cache || cache;
  const data = _cache[key];
  if (!data) {
    return;
  }

  if (data.expiry < Date.now()) {
    return;
  }

  return data.value;
};

const CacheHelper = { get, set, del, deleteByPrefix };
export default CacheHelper;
