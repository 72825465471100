import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import Logo from "./logo.png";
import navConfig from "./navConfig";
import { useLocation, matchPath } from "react-router-dom";
import UserContext from "../../contexts/UserContext";
import { useContext } from "react";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import OrganizationHelper from "../../helpers/OrganizationHelper";

export default function NavBar() {
  const { pathname } = useLocation();
  const { initiateLogout, organization } = useContext(UserContext);

  //To match current url and nav url
  const match = (path) => (path ? matchPath(`${path}/*`, pathname) : false);

  return (
    <div className={styles.baseWrapper}>
      <div className={styles.brandNameWrapper}>
        <img src={Logo} className={styles.logo} alt="" />
        <span className={styles.brandName}>Eazy cart</span>
      </div>
      {navConfig.map((nav) => {
        const navItemConfig = OrganizationHelper.getPageConfig(
          organization,
          nav.id
        );
        if (!navItemConfig) {
          return <div key={nav.id} />;
        }

        return (
          <div
            key={nav.id}
            className={` ${match(nav.path) ? styles.activeNavLink : null}`}
          >
            <Link
              key={nav.name}
              className={`${styles.navLinkWrapper} ${
                match(nav.path) ? styles.activeNavLink : null
              }`}
              to={nav.path}
            >
              <nav.icon className={styles.navLinkIcon} />
              <h4 className={styles.navLink}>{nav.name}</h4>
            </Link>
            {nav.subMenu &&
              match(nav.path) &&
              nav.subMenu.map((subMenu) => {
                return (
                  <Link className={styles.navSubMenuItem} to={subMenu.path}>
                    <nav.icon className={styles.subMenuLinkIcon} />
                    <h5 className={styles.subMenuLink}>{subMenu.name}</h5>
                  </Link>
                );
              })}
          </div>
        );
      })}

      {/*Logout*/}
      <Link
        key="logout"
        className={styles.logoutWrapper}
        onClick={initiateLogout}
      >
        <LogoutRoundedIcon className={styles.navLinkIcon} />
        <span className={styles.navLink}>Logout</span>
      </Link>
    </div>
  );
}

// {nav.subMenu &&
//   match(nav.path) &&
//   nav.subMenu.map((subMenu) => {
//     return (
//       <div className={styles.navSubMenuItem}>
//         <nav.icon className={styles.subMenuLinkIcon} />
//         <h5 className={styles.subMenuLink}>{subMenu.name}</h5>
//       </div>
//     );
//   })}
