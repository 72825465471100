import styles from "./styles.module.css";
import Logo from "./logo.png";
import brandLogo from "./brand.png";
import * as React from "react";
import { Grid } from "@mui/material";

export default function withFormPageLayout(FormComponent) {
  return () => {
    return (
      <Grid container className={styles.userWrapper} justifyContent="center">
        <Grid item container className={styles.formContainer}>
          <Grid item xs={12} md={6} lg={5}>
            <div className={styles.logoContainer}>
              <img src={Logo} alt="" />
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={7}>
            <div className={styles.contentContainer}>
              <img src={brandLogo} className={styles.brandLogo} alt="" />
              <div className={styles.outletWrapper}>
                <FormComponent />
              </div>
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
  };
}
