import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmPopup({
  data,
  handleClose,
  handleSuccess,
  alertTitle,
  alertContent,
  successBtnName,
  open,
}) {
  return (
    <Dialog
      open={open || true}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {alertContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            handleClose("");
          }}
          color="primary"
        >
          cancel
        </Button>
        <Button
          onClick={() => {
            handleSuccess(data);
            handleClose();
          }}
          color="primary"
        >
          {successBtnName}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
