import { useState, useEffect, useContext, useCallback } from "react";
import { Grid } from "@mui/material";
import loadingContext from "../../contexts/loadingContext";
import SaleService from "../../services/SaleService";
import OpenOrderDetail from "../../dialogs/OpenOrderDetail";
import OrganizationGuard from "../../components/OrganizationGuard";
import ConsoleLayout from "../../components/Layout/ConsoleLayout";
import OrderCard from "./OrderCard";
import Loader from "../../components/Loader";
import { SALE_STATUS } from "../../constants";
import styles from "./styles.module.css";

const PENDING_SALE_STATUSES = [
  SALE_STATUS.DRAFT,
  SALE_STATUS.CONFIRMED,
  SALE_STATUS.PAID,
  SALE_STATUS.PACKING,
];

const PENDING_SALE_STATUS_STR = PENDING_SALE_STATUSES.join(",");

export default function SalesManagementPage() {
  const { isLoading, setIsLoading } = useContext(loadingContext);
  const [sales, setSales] = useState(null);
  const [selectedSale, setSelectedSale] = useState(null);

  const updateSaleStatus = async (id, status) => {
    setIsLoading(true);
    try {
      const sale = await SaleService.updateSale(id, { status });

      if (!PENDING_SALE_STATUSES.includes(sale.status)) {
        // Removing sale from existing pending sales list
        setSales(sales.filter((_sale) => _sale.id !== id));
      } else {
        // Updating sales details to the existing sales list
        const saleIndex = sales.findIndex((_sale) => _sale.id === id);
        sales[saleIndex] = sale;
        setSales([...sales]);
      }
    } catch (err) {
      console.error(err.message);
      alert("SALE UPDATE FAILED");
    }
    setIsLoading(false);
  };

  const getSales = useCallback(async () => {
    try {
      setIsLoading(true);
      const sales = await SaleService.getSales({
        status: PENDING_SALE_STATUS_STR,
      });
      setSales(sales);
    } catch (err) {
      console.error(err.message);
      alert("SALES LOADING FAILED");
    }
    setIsLoading(false);
  }, [setIsLoading]);

  useEffect(() => {
    getSales().then();
  }, [getSales]);

  return (
    <OrganizationGuard>
      <ConsoleLayout>
        <Loader isLoading={isLoading} size={16} />
        <div className={styles.ordersContentWrapper}>
          <div className={styles.titleBarWrapper}>
            <div className={styles.title}>Orders</div>
          </div>
          <Grid
            container
            spacing={2}
            alignContent="flex-start"
            className={styles.ordersWrapper}
          >
            {sales ? (
              sales.length ? (
                sales.map((sale) => (
                  <OrderCard
                    key={sale.id}
                    sale={sale}
                    setSelectedSale={setSelectedSale}
                    updateSaleStatus={updateSaleStatus}
                  />
                ))
              ) : (
                <div style={{ padding: 16 }}>No pending sales to manage</div>
              )
            ) : (
              <Loader isLoading={true} />
            )}
          </Grid>
        </div>

        {selectedSale && (
          <OpenOrderDetail
            sale={selectedSale}
            handleClose={() => {
              setSelectedSale(null);
            }}
          />
        )}
      </ConsoleLayout>
    </OrganizationGuard>
  );
}
