import NavBar from "../NavBar/NavBar";
import styles from "./styles.module.css";

export default function ConsoleLayout({ children }) {
  return (
    <div className={styles.ContentsWrapper}>
      <div className={styles.NavbarWrapper}>
        <NavBar />
      </div>
      <div className={styles.ContentsArea}>{children}</div>
    </div>
  );
}
