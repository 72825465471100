import React, { useState } from "react";
import TextInput from "../components/TextInput";
import PopUpLayout from "../components/Layout/PopUpLayout";
import Loader from "../components/Loader";
import styles from "./styles.module.css";

function AddCustomerDialog({ closeDialog }) {
  // const [isLoading, setIsLoading] = useState(false);
  const [isLoading] = useState(false);

  return (
    <PopUpLayout className={styles.size}>
      <div className={styles.title}>Customer Details</div>
      <TextInput name="name" label="Name" placeholder="Customer Name" />
      <TextInput
        name="name"
        label="Mobile Number"
        placeholder="Type Mobile Number"
      />
      <TextInput
        name="Loyality card number"
        label="Loyality card number"
        placeholder="Type Loyality card number"
      />
      <TextInput
        name="Loyality balance"
        label="Loyality balance"
        placeholder="Type loyality balance"
      />
      <TextInput
        name="Credit limit"
        label="Credit limit"
        placeholder="Type credit limit"
      />
      <TextInput name="Balance" label="Balance" placeholder="Balance" />
      <div className={styles.btnWrapper}>
        <button type="reset" className={styles.closeBtn} onClick={closeDialog}>
          Cancel
        </button>
        <button
          style={{ position: "relative" }}
          type="button"
          className={styles.submitBtn}
        >
          <Loader isLoading={isLoading} size={16} />
          Submit
        </button>
      </div>
    </PopUpLayout>
  );
}

export default AddCustomerDialog;
