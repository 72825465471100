import React, { useCallback, useContext, useEffect, useState } from 'react';
import styles from './ItemFormPage.module.css';
import TextInput from '../components/TextInput';
import ItemService from '../services/ItemService';
import Loader from '../components/Loader';
import { Breadcrumbs, Grid, IconButton, TextField } from '@mui/material';
import AddBoxIcon from '@mui/icons-material/AddBox';
import EditIcon from '@mui/icons-material/Edit';
import OrganizationGuard from '../components/OrganizationGuard';
import ConsoleLayout from '../components/Layout/ConsoleLayout';
import EastSharpIcon from '@mui/icons-material/EastSharp';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from '../constants';
import CategoryService from '../services/CategoryService';
import AlertMessageContext from '../contexts/AlertMessageContext';
import ImagesPanel from '../components/ImagesPanel';
import { Draggable, Droppable } from 'react-drag-and-drop';
import DeleteIcon from '@mui/icons-material/Delete';

const DRAGGABLE_TYPE = 'specification';

export default function ItemFormPage() {
  return (
    <OrganizationGuard>
      <ConsoleLayout>
        <ItemForm />
      </ConsoleLayout>
    </OrganizationGuard>
  );
}

function ItemForm() {
  const defaultSpecFormValues = {
    label: '',
    value: '',
  };

  const { categoryId, itemId } = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState(null);
  const [item, setItem] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [specFormData, setSpecFormData] = useState(defaultSpecFormValues);
  const { postErrorAlert } = useContext(AlertMessageContext);

  const categoryPageUrl = ROUTES.CONSOLE_CATEGORY_DETAILS.replace(
    ':categoryId',
    categoryId
  );
  const [errors, setErrors] = useState({});

  const _getData = () => {
    return {
      ...item,
      categoryId,
      images: item.images?.filter((image) => !image.file),
      description: item.description || '',
    };
  };

  async function createHandler() {
    setIsActionLoading(true);
    try {
      // const data = _getDataFromFormikValues(values);
      const createdItem = await ItemService.addItem(_getData());
      await uploadImages(createdItem.id, item.images);
      resetForm();
      moveBackToCategoryDetails();
    } catch (err) {
      setErrors({ ...errors, name: err.message });
    }
    setIsActionLoading(false);
  }

  const editHandler = async () => {
    setIsActionLoading(true);
    try {
      await ItemService.updateItem(item.id, _getData());
      await uploadImages(item.id, item.images);
      resetForm();
      moveBackToCategoryDetails();
    } catch (err) {
      setErrors({ ...errors, name: err.message });
    }
    setIsActionLoading(false);
  };

  const uploadImages = async (itemId, images) => {
    const imagesToUpload = images?.filter((image) => image.file);
    if (!imagesToUpload || !imagesToUpload.length) {
      return;
    }

    for (const image of imagesToUpload) {
      await ItemService.addItemImage(itemId, image.file);
    }
  };

  const moveBackToCategoryDetails = () => {
    navigate(categoryPageUrl);
  };

  const onNewImage = async (image) => {
    const images = [...(item.images || []), image];
    setItem({ ...item, images });
  };

  const onRemoveImage = async (imageId) => {
    const images = (item.images || []).filter(
      (itemImage) => itemImage.id !== imageId
    );
    setItem({ ...item, images });
  };

  const getCategory = useCallback(async () => {
    try {
      const category = await CategoryService.getCategory(categoryId);
      setCategory(category);
    } catch (error) {
      postErrorAlert(error.message);
    }
  }, [categoryId, postErrorAlert]);

  const getItem = useCallback(async () => {
    if (!itemId) {
      return null;
    }
    try {
      const item = await ItemService.getItem(itemId);
      setItem(item);
    } catch (error) {
      postErrorAlert(error.message);
    }
  }, [itemId, postErrorAlert]);

  const onSubmit = () => {
    item.id ? editHandler() : createHandler();
  };
  const resetForm = () => {
    setItem({});
  };

  useEffect(() => {
    const loadDetails = async () => {
      setIsLoading(true);
      await Promise.all([getCategory(), getItem()]);
      setIsLoading(false);
    };
    loadDetails().then();
  }, [categoryId, setIsLoading, postErrorAlert, getItem, getCategory]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className={styles.titleWrapper}>
        {category && (
          <>
            <span className={styles.title}>
              Add Item to{' '}
              <span className={styles.activeText}>{category?.name}</span>
            </span>
            <Breadcrumbs
              separator={<EastSharpIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              <Link
                to={ROUTES.CONSOLE_PRODUCTS}
                underline="hover"
                key="1"
                className={styles.link}
              >
                Categories
              </Link>
              <Link
                to={categoryPageUrl}
                underline="hover"
                key="1"
                className={styles.link}
              >
                {category?.name}
              </Link>
              <span key="2" className={styles.activeLink}>
                Add Item
              </span>
            </Breadcrumbs>
          </>
        )}
      </div>
      <ImagesPanel
        images={item?.images || []}
        addImage={onNewImage}
        removeImage={onRemoveImage}
      />
      <TextInput
        name="name"
        label="Item name"
        placeholder="Enter item name"
        error={errors.name}
        value={item.name}
        onChange={(value) => setItem({ ...item, name: value })}
      />
      <TextInput
        label="Description"
        name="description"
        multiline
        rows={4}
        placeholder="Enter item description"
        error={errors.description}
        value={item.description}
        onChange={(value) => setItem({ ...item, description: value })}
      />
      <TextInput
        name="price"
        label="Price"
        placeholder="Enter item price"
        error={errors.price}
        value={item.price}
        onChange={(value) => setItem({ ...item, price: value })}
      />
      <TextInput
        name="order"
        label="Order"
        placeholder="Enter order"
        value={item.order}
        error={errors.order}
        onChange={(order) => setItem({ ...item, order: Number(order) })}
      />
      <Grid
        container
        spacing={2}
        alignItems="flex-end"
        style={{ marginTop: 10 }}
      >
        <Grid item xs={12} className={styles.subTitle}>
          Specifications
        </Grid>
        <Grid item xs={12}>
          {(item?.specifications || []).map((specification) => {
            return (
              <React.Fragment key={specification.label}>
                <Droppable
                  types={[DRAGGABLE_TYPE]}
                  onDrop={(data) => {
                    const draggedInLabel = data.specification;
                    if (specification.label === draggedInLabel) {
                      return;
                    }

                    const existingSpecifications = item?.specifications || [];
                    const draggedInSpecIndex = existingSpecifications.findIndex(
                      (s) => s.label === draggedInLabel
                    );
                    const draggedInSpecification =
                      existingSpecifications.splice(draggedInSpecIndex, 1)[0];

                    const indexOfCurrentSpec = existingSpecifications.findIndex(
                      (s) => s.label === specification.label
                    );

                    const specifications = [
                      ...existingSpecifications.slice(0, indexOfCurrentSpec),
                      {
                        ...draggedInSpecification,
                        order: indexOfCurrentSpec + 1,
                      },
                      ...existingSpecifications
                        .slice(indexOfCurrentSpec)
                        .map((s) => ({ ...s, order: s.order + 1 })),
                    ]
                      .sort((a, b) => a.order - b.order)
                      .map((s, index) => ({ ...s, order: index + 1 }));
                    setItem({ ...item, specifications });
                  }}
                >
                  <div style={{ margin: 10 }}>&nbsp;</div>
                </Droppable>
                <Draggable type={DRAGGABLE_TYPE} data={specification.label}>
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        variant="standard"
                        type="text"
                        value={specification.label}
                        fullWidth
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        variant="standard"
                        type="text"
                        value={specification.value}
                        fullWidth
                        disabled={true}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        component="span"
                        onClick={() => {
                          setSpecFormData(specification);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        component="span"
                        onClick={() => {
                          const specifications = item?.specifications.filter(
                            (s) => s.label !== specification.label
                          );
                          setItem({ ...item, specifications });
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Draggable>
              </React.Fragment>
            );
          })}
        </Grid>
        <Grid item xs={4}>
          <TextField
            name="specificationLabel"
            label="Label"
            placeholder="Enter label"
            variant="standard"
            type="text"
            value={specFormData.label}
            fullWidth
            onChange={(event) => {
              setSpecFormData({ ...specFormData, label: event.target.value });
            }}
          />
        </Grid>
        <Grid item xs={7}>
          <TextField
            name="specificationValue"
            label="Value"
            placeholder="Enter specification value"
            variant="standard"
            type="text"
            value={specFormData.value}
            fullWidth
            onChange={(event) => {
              setSpecFormData({ ...specFormData, value: event.target.value });
            }}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton
            component="span"
            onClick={() => {
              const existingSpecifications = (item?.specifications || [])
                .filter((s) => s.label !== specFormData.label)
                .map((s, index) => ({ ...s, order: index + 1 }));

              const specifications = [
                ...existingSpecifications,
                {
                  label: specFormData.label,
                  value: specFormData.value,
                  order: existingSpecifications.length + 1,
                },
              ];
              setItem({
                ...item,
                specifications,
              });
              setSpecFormData(defaultSpecFormValues);
            }}
          >
            <AddBoxIcon />
          </IconButton>
        </Grid>
      </Grid>
      <div className={styles.btnWrapper}>
        <button className={styles.closeBtn} onClick={moveBackToCategoryDetails}>
          Cancel
        </button>
        <button
          type="submit"
          style={{ position: 'relative' }}
          className={styles.submitBtn}
          onClick={onSubmit}
        >
          <Loader isLoading={isActionLoading} size={16} />
          Submit
        </button>
      </div>
    </>
  );
}
