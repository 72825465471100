import { BASE_API_URL } from "../constants";
import ApiHelper from "../helpers/ApiHelper";

const getSales = (filter) => {
  const url = `${BASE_API_URL}/sales`;
  return ApiHelper.request({
    url,
    method: "GET",
    params: filter,
    requireAuth: true,
    requireOrg: true,
  });
};
const updateSale = (id, data) => {
  const url = `${BASE_API_URL}/sales/${id}`;
  return ApiHelper.request({
    url,
    method: "PATCH",
    data,
    requireAuth: true,
    requireOrg: true,
  });
};

const SaleService = {
  getSales,
  updateSale,
};

export default SaleService;
