import OrganizationGuard from "../../components/OrganizationGuard";
import ConsoleLayout from "../../components/Layout/ConsoleLayout";
export default function PriceCategory() {
  return (
    <OrganizationGuard>
      <ConsoleLayout>
        <h2>Price Category</h2>
      </ConsoleLayout>
    </OrganizationGuard>
  );
}
