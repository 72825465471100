// import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import CategoryOutlinedIcon from "@mui/icons-material/CategoryOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import PersonIcon from "@mui/icons-material/Person";
import { ROUTES } from "../../constants";

const navConfig = [
  // {
  //   id: "dashboard",
  //   name: "Dashboard",
  //   path: "/console/dashboard",
  //   icon: DashboardOutlinedIcon,
  // },
  {
    id: "sales",
    name: "Sales",
    path: ROUTES.CONSOLE_SALES,
    icon: ShoppingCartOutlinedIcon,
  },
  {
    id: "products",
    name: "Products",
    path: ROUTES.CONSOLE_PRODUCTS,
    icon: CategoryOutlinedIcon,
  },
  {
    id: "customers",
    name: "Customers",
    path: "/console/customers",
    icon: PersonIcon,
  },
  {
    id: "chargeConfig",
    name: "Charge Config",
    path: "/console/charge-config",
    icon: PersonIcon,
    subMenu: [
      {
        id: "groups",
        name: "Charge Groups",
        path: "/console/charge-config",
        icon: SettingsOutlinedIcon,
      },
      {
        id: "charges",
        name: "Charges",
        path: "/console/charges",
        icon: SettingsOutlinedIcon,
      },
    ],
  },
  {
    id: "users-permissions",
    name: "User Permissions ",
    path: "/console/permissions",
    icon: PersonIcon,
  },
  {
    id: "prices",
    name: "Price Category",
    path: "/console/prices",
    icon: PersonIcon,
  },
  {
    id: "report",
    name: "Report",
    path: "/console/report",
    icon: SummarizeOutlinedIcon,
    subMenu: [
      {
        id: "item",
        name: "Item Summary Report",
        path: "/console/settings/item",
        icon: SettingsOutlinedIcon,
      },
      {
        id: "itemised",
        name: "Itemised Summary Report",
        path: "/console/settings/itemised",
        icon: SettingsOutlinedIcon,
      },
      {
        id: "bill",
        name: "Bill Summary Report",
        path: "/console/settings/bills",
        icon: SettingsOutlinedIcon,
      },
    ],
  },
  {
    id: "settings",
    name: "Settings",
    path: ROUTES.CONSOLE_SETTINGS,
    icon: SettingsOutlinedIcon,
    subMenu: [
      {
        id: "abc",
        name: "Price Override",
        path: "/console/price",
        icon: SettingsOutlinedIcon,
      },
      {
        id: "roles",
        name: "User Roles",
        path: "/console/settings/efg",
        icon: SettingsOutlinedIcon,
      },
      {
        id: "loyalty",
        name: "Loyalty Points",
        path: "/console/loyalty-points",
        icon: SettingsOutlinedIcon,
      },
    ],
  },
];

export default navConfig;
