import ImageUpload from "../ImageUpload";
import React from "react";
import { getImageUrl } from "../../helpers/ImageHelper";
import styles from "./styles.module.css";
import CancelIcon from "@mui/icons-material/Cancel";
import { Grid } from "@mui/material";

export default function ImagesPanel({ images, addImage, removeImage }) {
  return (
    <div className={styles.layout}>
      <ImageUpload onImageSelected={addImage} />
      <div>
        <div>Images</div>
        <Grid container>
          {images.map((image) => (
            <Grid item key={image.id}>
              <div className={styles.imageItem}>
                <img
                  width="100"
                  height="100"
                  src={getImageUrl(image)}
                  alt={image.id}
                />
                <CancelIcon
                  className={styles.closeButton}
                  onClick={() => {
                    removeImage(image.id);
                  }}
                />
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
}
