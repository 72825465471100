import { useEffect, useState, useContext, useCallback } from "react";
import SearchIcon from "@mui/icons-material/Search";
import styles from "./CategoryListPage.module.css";
import CategoryForm from "../dialogs/CategoryForm";
import CategoryService from "../services/CategoryService";
import Info from "../components/Alert/Info";
import ConfirmPopup from "../components/Alert/ConfirmPopup";
import { ROUTES } from "../constants";
import OrganizationGuard from "../components/OrganizationGuard";
import ConsoleLayout from "../components/Layout/ConsoleLayout";
import Loader from "../components/Loader";
import AlertMessageContext from "../contexts/AlertMessageContext";
import CategoryCard from "../components/CategoryCard";

export default function CategoryListPage() {
  return (
    <OrganizationGuard>
      <ConsoleLayout>
        <CategoryList />
      </ConsoleLayout>
    </OrganizationGuard>
  );
}

function CategoryList() {
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedCategoryForDelete, setSelectedCategoryForDelete] =
    useState(null);
  const { postErrorAlert } = useContext(AlertMessageContext);

  const openAddCategory = () => setSelectedCategory({});
  const closeAddCategory = () => {
    setSelectedCategory(null);
  };
  const onCategoryActionSuccess = () => {
    getCategories().then();
  };
  const editHandler = (category) => {
    setSelectedCategory(category);
  };
  const openDeleteConfirmPopup = (category) => {
    setSelectedCategoryForDelete(category);
  };
  const closeDeleteConfirmPopup = () => {
    setSelectedCategoryForDelete(null);
  };
  const deleteCategory = async (category) => {
    setIsLoading(true);
    try {
      await CategoryService.deleteCategory(category.id);
      await getCategories();
    } catch (err) {
      postErrorAlert(err.message);
    }
    setIsLoading(false);
  };
  const getCategories = useCallback(async () => {
    setIsLoading(true);
    try {
      const res = await CategoryService.getCategories();
      setCategories(res);
    } catch (err) {
      postErrorAlert(err.message);
    }
    setIsLoading(false);
  }, [setIsLoading, postErrorAlert]);

  useEffect(() => {
    getCategories().then();
  }, [setIsLoading, getCategories]);

  return (
    <>
      <Loader isLoading={isLoading} />
      <div className={styles.titleBarWrapper}>
        <div className={styles.titleWrapper}>
          <span className={styles.title}>Categories</span>
        </div>
        <div className={styles.filterWrapper}>
          <div className={styles.searchBarWrapper}>
            <SearchIcon className={styles.searchIcon} />
            <input
              type="text"
              className={styles.searchBar}
              placeholder="Search"
            />
          </div>
          <span className={styles.coloredBtn} onClick={openAddCategory}>
            Add New Category
          </span>
          {selectedCategory && (
            <CategoryForm
              category={selectedCategory}
              closeHandler={closeAddCategory}
              onSuccess={onCategoryActionSuccess}
            />
          )}
          {selectedCategoryForDelete && (
            <ConfirmPopup
              data={selectedCategoryForDelete}
              alertTitle="Are you sure to delete category?"
              alertContent={`You are going to delete '${selectedCategoryForDelete.name}' category.
                                This action is permanent and can't retrieve the details once deleted.`}
              successBtnName="Delete"
              handleClose={closeDeleteConfirmPopup}
              handleSuccess={deleteCategory}
            />
          )}
        </div>
      </div>
      <div className={styles.cardsWrapper}>
        {categories && !categories.length ? (
          <Info
            title="No Category Items Found"
            content="This shop does not have any categories to list"
          />
        ) : (
          categories?.map((category) => (
            <CategoryCard
              key={category.id}
              content={category}
              link={ROUTES.CONSOLE_CATEGORY_DETAILS.replace(
                ":categoryId",
                category.id
              )}
              editHandler={editHandler}
              deleteHandler={openDeleteConfirmPopup}
            />
          ))
        )}
      </div>
    </>
  );
}
